<template lang="pug">
section.start
  .start__bg
    Illustration.start__svg
    IllustrationTopLayer.start__svg-layer.start__svg-layer_top
    .start__space#star-field
    IllustrationBackLayer.start__svg-layer.start__svg-layer_back
    img(
      :src="'/svg/lp/IllustrationMiddleLayer.svg'"
    ).start__svg-layer.start__svg-layer_middle

  .start__container
    .lp-headline-text.start__text
      h1.lp-headline-subtitle {{ $t('pages.ssr_landing.start_screen.subtitle') }}
      h3.lp-headline-title {{ $t('pages.ssr_landing.start_screen.title') }}
      p.lp-headline-description {{ $t('pages.ssr_landing.start_screen.description') }}
      RssUiButton.start__text-button.lp-button.link-button-arrow-animation(
        :data-test-id="buttonDataTestId"
        :href="buttonHref"
        :blank="false"
        rainbow
      )
        template {{ buttonText }}
        .link-button-arrow-animation__i
          SmallArrowIcon

      span.start__text-card(
        v-if="!isLoggedIn"
      ) {{ $t('pages.ssr_landing.start_screen.no_credit_card') }}
</template>

<script>
import { mapState } from 'vuex'

import Illustration from './svg/Illustration'
import IllustrationTopLayer from './svg/IllustrationTopLayer'
import IllustrationBackLayer from './svg/IllustrationBackLayer'
import IllustrationMiddleLayer from './svg/IllustrationMiddleLayer'

import SmallArrowIcon from '@/components/icons/default/ArrowSmall'

import { config } from '@/config'

export default {
  components: {
    SmallArrowIcon,
    Illustration,

    IllustrationTopLayer,
    IllustrationBackLayer,
    IllustrationMiddleLayer
  },

  computed: {
    ...mapState('auth', ['isLoggedIn']),

    dashboardAppUrl () {
      return config.dashboardAppUrl
    },

    buttonDataTestId () {
      if (!this.isLoggedIn) {
        return 'buttonSignupCTA'
      }

      return undefined
    },

    buttonText () {
      if (!this.isLoggedIn) {
        return this.$t('pages.ssr_landing.start_screen.button')
      }
      return this.$t('homepage.hero.button_logged')
    },

    buttonHref () {
      if (!this.isLoggedIn) {
        return this.$locatedLink('/auth/sign-up/', this.dashboardAppUrl)
      }
      return this.$locatedLink('/', this.dashboardAppUrl)
    }
  },

  mounted () {
    const $starField = document.getElementById('star-field')
    const $svgIllustration = document.querySelector('.start__svg')
    const $backIllustrationLayer = document.querySelector('.start__svg-layer.start__svg-layer_back')

    const numberOfStars = 18
    const starAnimationSpeed = 0.1
    if ($starField) {
      const starFieldWidth = $starField.offsetWidth
      const starFieldHeight = $backIllustrationLayer.getBBox().height || ($svgIllustration.getBBox().height * 0.8)

      this.addStars(starFieldWidth, starFieldHeight, numberOfStars)
      this.animateStars(starFieldWidth, starAnimationSpeed)
    }
  },

  methods: {
    addStars (starFieldWidth, starFieldHeight, noOfStars) {
      const starField = document.getElementById('star-field')
      const numberOfStars = noOfStars

      for (let i = 0; i < numberOfStars; i++) {
        const star = document.createElement('div')
        star.className = 'star'
        const topOffset = Math.floor((Math.random() * starFieldHeight) + 1)
        const leftOffset = Math.floor((Math.random() * starFieldWidth) + 1)
        star.style.top = topOffset + 'px'
        star.style.left = leftOffset + 'px'
        star.style.position = 'absolute'
        starField.appendChild(star)
      }
    },

    animateStars (starFieldWidth, speed) {
      const starField = document.getElementById('star-field')
      const stars = starField.childNodes

      function getStarColor (index) {
        if (index % 8 === 0) {
          return 'red'
        } else if (index % 10 === 0) {
          return 'yellow'
        } else if (index % 17 === 0) {
          return 'blue'
        } else {
          return 'white'
        }
      }

      function getStarDistance (index) {
        if (index % 6 === 0) {
          return ''
        } else if (index % 9 === 0) {
          return 'near'
        } else if (index % 2 === 0) {
          return 'far'
        } else {
          return 0
        }
      }

      function getStarRelativeSpeed (index) {
        if (index % 6 === 0) {
          return 0.1
        } else if (index % 9 === 0) {
          return 0.4
        } else if (index % 2 === 0) {
          return 0.2
        } else {
          return 0.1
        }
      }

      setInterval(function () {
        for (let i = 0; i < stars.length; i++) {
          stars[i].className = 'star' + ' ' + getStarColor(i) + ' ' + getStarDistance(i)

          const currentLeft = parseInt(stars[i].style.left, 10)
          const leftChangeAmount = speed + getStarRelativeSpeed(i)
          let leftDiff

          if (currentLeft - leftChangeAmount < 0) {
            leftDiff = currentLeft - leftChangeAmount + starFieldWidth
          } else {
            leftDiff = currentLeft - leftChangeAmount
          }
          stars[i].style.left = (leftDiff) + 'px'
        }
      }, 20)
    }
  }
}
</script>

<style lang="scss" scoped>
.lp-headline {
  &-subtitle {
    font-size: 16px;
  }

  &-title {
    @include display-less(desktop) {
      font-size: 38px;
    }

    @include display-less(phablet) {
      font-size: 26px;
    }
  }
}

.start {
  width: 100%;
  height: auto;
  position: relative;
  background: #1C0C32;

  @include display-less(tablet) {
    height: auto;
    display: flex;
    flex-direction: column-reverse;
  }

  &__container {
    width: 100%;
    margin: 0 auto;
    max-width: 1498px;
  }

  &__space {
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 400px;
    position: absolute;
  }

  &__text-button {
    @include display-less(smart) {
      /deep/ .rss-ui-button__text {
        font-size: 15px;
      }
    }
  }

  &__bg {
    overflow-x: hidden;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    margin-top: $gap;
    position: relative;
    margin-top: -$gap * 0.5;
    justify-content: center;

    @include display-less(tablet) {
      width: 100%;
      position: relative;
    }
  }

  &__svg {
    width: 100%;
    height: auto;
    display: none;
    min-width: 100%;

    @media (max-width: 1450px) {
      width: 1440px;
      height: auto;
      min-width: 1440px;
    }

    @include display-less(tablet) {
      width: 100%;
      height: auto;
      display: block;
      min-width: 100%;
      height: intrinsic;
    }
  }

  &__svg-layer {
    display: block;

    &_top, &_back {
      position: absolute;
    }

    &_middle {
      z-index: 3;
      width: 100%;
      height: 370px;
      margin-top: 330px;
      position: relative;

      @include display(max) {
        margin-top: 473px;
      }
    }

    &_top {
      left: 50%;
      z-index: 4;
      bottom: 20px;
      margin-left: -594px;

      @include display-less(desktop) {
        margin-left: -700px;
      }
    }

    &_back {
      left: 50%;
      z-index: 2;
      bottom: 40px;
      margin-left: -316px;
    }

    @include display-less(tablet) {
      display: none;
    }
  }

  &__text {
    top: 0;
    z-index: 2;
    position: absolute;

    @media (min-width: 1562px) {
      width: 720px;
      padding-left: 0;
      padding-right: 0;
    }

    @include display-less(big) {
      padding-left: $gap * 2;
      padding-right: $gap * 2;
      width: 550px + $gap * 4;
    }

    @include display-less(desktop) {
      width: 60%;
    }

    @include display-less(tablet) {
      width: 100%;
      position: relative;
      padding-left: $gap;
      padding-right: $gap;
      padding-top: 1.5 * $gap;
    }

    &-card {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 160%;
      margin-top: $gap * 0.5;

      @include display-less(tablet) {
        width: 100%;
        text-align: center;
      }

      @include display(max) {
        font-size: 18px;
      }
    }

    &-button {
      @include display-less(tablet) {
        width: 100%;
      }

      @include display(max) {
        :deep(.rss-ui-button) {
          height: 60px;
          font-size: 22px;
          min-height: 60px;
          line-height: 60px;
        }
      }
    }
  }
}

$starDimension: 2px;

.start__space {
  ::v-deep .star {
    width: $starDimension;
    height: $starDimension;
    position: absolute;
    background: #fff;
    border-radius: 50%;

    &.far {
      width: $starDimension - 1;
      height: $starDimension - 1;
    }

    &.near {
      width: $starDimension + 2;
      height: $starDimension + 2;
    }

    &.white {
      background: #fff;
      box-shadow: 0 0 15px #fff;
    }

    &.yellow {
      background: #BFBB40;
      box-shadow: 0 0 15px #BFBB40;
    }

    &.blue {
      background: #4444ff;
      box-shadow: 0 0 15px #4444ff;
    }

    &.red {
      background: #ff4444;
      box-shadow: 0 0 15px #ff4444;
    }
  }

  @include display-less(tablet) {
    display: none;
  }
}

.link-button-arrow-animation {
  @include display(max) {
    .link-button-arrow-animation__i {
      width: 20px;
      height: 20px;

      ::v-deep svg {
        width: 100%;
        height: auto;
      }

      &:after {
        right: 4px;
        height: 4px;
        margin-top: -2px;
      }
    }
    &:hover {
      .link-button-arrow-animation__i {
        &:after {
          width: 14px;
        }
      }
    }
  }
}
</style>
